const apiUrl = process.env.VUE_APP_OUTSOURCING_READINESS_ASSESSMENT_ENDPOINT; // https://6e7jnspkv3.execute-api.us-east-1.amazonaws.com
const oraPath = '/v1/member/prospect/ora'

async function getORA(assessmentId) {
    console.log("entered getORA()");

    let result = null;
    let url = `${apiUrl}${oraPath}/${assessmentId}`;
    console.log("url", url);

    let response = await fetch(url);
    //alert(response.status);

    switch (response.status) {
        case 200:
            //console.log("Returned status=200");
            result = await response.json();
            break;
        default:
            //console.log(`status code = ${response.status}`)
            result = null;
            break;
    }

    //console.log("assessment from db", result)
    return result;
}

async function getAll() {
    console.log("entered getAll()");

    let result = null;
    let url = `${apiUrl}${oraPath}/all`;
    console.log("url", url);

    let response = await fetch(url);
    //alert(response.status);

    switch (response.status) {
        case 200:
            //console.log("Returned status=200");
            result = await response.json();
            break;
        default:
            //console.log(`status code = ${response.status}`)
            result = null;
            break;
    }

    result = result.filter(o => o.recordId.startsWith("ORA:"));    //console.log("assessment from db", result)
    return result;
}


async function deleteORA(assessmentId) {
    console.log("entered updateORA()");

    //var result = null;
    let url = `${apiUrl}${oraPath}/${assessmentId}`;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
        method: 'DELETE',
        mode: 'cors',
        headers: myHeaders,
        redirect: 'follow'
    };

    await fetch(url, requestOptions)
        .catch(error => console.log('error', error));

    return //result;
}
 

async function getORAByEmail(email) {
    console.log("entered getORAByEmail()");

    let result = null;
    let url = `${apiUrl}${oraPath}/emailIndex/ORA:${email}`;
    console.log("url", url);

    let response = await fetch(url);
    //alert(response.status);

    switch (response.status) {
        case 200:
            //console.log("Returned status=200");
            result = await response.json();
            break;
        default:
            //console.log(`status code = ${response.status}`)
            result = null;
            break;
    }

    //console.log("assessment from db", result)
    return result;
}




// Creates a new record in AWS DynamoDB
async function updateORA(ora) {
    console.log("entered updateORA()");

    var result = null;
    let url = `${apiUrl}${oraPath}/${ora.recordId}`;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
        method: 'PUT',
        mode: 'cors',
        headers: myHeaders,
        body: JSON.stringify(ora),
        redirect: 'follow'
    };

    await fetch(url, requestOptions)
        .then(response => {
            result = response.json();
        })
        .catch(error => console.log('error', error));

    return result;
}

async function getReport(assessmentId, type) {
    console.log("entered getSummaryReport()");

    type = type.trim().toLowerCase();

    let validTypes = ["summary", "graph", "pdf"]

    if (validTypes.indexOf(type) === -1) {
        console.log("Invalid report type; valid values are 'summary', 'graph', or 'pdf'");
        return null;
    }

    let result = null;
    let url = `${apiUrl}${oraPath}/${assessmentId}/report/${type}`;
    console.log("url", url);

    let response = await fetch(url);
    //alert(response.status);

    switch (response.status) {
        case 200:
            //console.log("Returned status=200");
            result = await response.json();
            break;
        default:
            //console.log(`status code = ${response.status}`)
            result = null;
            break;
    }

    //console.log("assessment from db", result)
    return result
}





module.exports.get = getORA;
module.exports.update = updateORA;
module.exports.deleteById = deleteORA;
module.exports.getReport = getReport;
module.exports.getORAByEmail = getORAByEmail;
module.exports.getAll = getAll;

