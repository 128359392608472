<template>
   <div class="component-name">

      <div v-if="oraLoaded == false">
         Loading ORA...
         <ProgressSpinner />
      </div>
      <div v-if="oraLoaded == true" class="detailsContainer">
         <div style="display: grid; grid-template-columns: 190px auto; margin-bottom: 4rem;">
            <div class="companyName">{{ ora.responses.companyInfo.name }}</div>

            <div class="completionStatus" :class="ora.completionStatus === 'Complete' ? 'complete' : 'inProgress'">Status:
               {{
                  ora.completionStatus === 'Complete' ? 'Complete' : 'In Progress' }}</div>

            <div class="infoHeader name">Segment:</div>
            <div class="infoHeader value segment">{{ segment(ora.responses.companyInfo?.sizeSegmentationCode) }}</div>

            <div class="infoHeader name">Created by:</div>
            <div class="infoHeader value">{{ ora.responses.userInfo.firstName + ' ' + ora.responses.userInfo.lastName }}
            </div>

            <div class="infoHeader name">Email:</div>
            <div class="infoHeader value">{{ ora.responses.userInfo.email }}</div>

            <div class="infoHeader name">ORA ID:</div>
            <div class="infoHeader value">{{ ora.recordId }}</div>

            <div class="infoHeader name">Started:</div>
            <div class="infoHeader value">{{ formatDate(ora.metadata.created_ts) }}</div>

            <div class="infoHeader name">Last Update:</div>
            <div class="infoHeader value">{{ formatDate(ora.metadata.updated_ts) }}</div>

            <div class="infoHeader name">HubSpot records:</div>
            <div class="infoHeader value">
               <a class="hubspotLinks" :href="'https://app.hubspot.com/contacts/8634369/contact/' + ora.crm.contactId"
                  target='new'>HubSpot Contact
                  record</a>
               <br />
               <a class="hubspotLinks" :href="'https://app.hubspot.com/contacts/8634369/company/' + ora.crm.companyId"
                  target='new'>HubSpot Company
                  record</a>
            </div>


         </div>
         <Panel ref="summaryPanel" header="Scoring Chart" toggleable :collapsed="true"
            v-if="ora.completionStatus == 'Complete'">

            <div class="card" v-if="ora.completionStatus == 'Complete'">
               <DataTable :value="scoringData" tableStyle="min-width: 50rem">
                  <Column field="section" header="Scoring Category"></Column>
                  <Column field="baseline" header="Baseline Readiness" headerStyle="width: 15rem; text-align: center"
                     bodyStyle="text-align:center;"></Column>
                  <Column field="boosted" header="Boosted Readiness" headerStyle="width: 15rem; text-align: center"
                     bodyStyle="text-align:center"></Column>
                  <Column field="delta" header="Delta" headerStyle="width: 15rem; text-align: center"
                     bodyStyle="text-align:center">
                     <template #body="slotProps">
                        <div class="deltaScore" :class="slotProps.data.deltaCss">+ {{ slotProps.data.delta }}</div>
                     </template>
                  </Column>
                  <ColumnGroup type="footer">
                     <Row>
                        <Column footer="Cumulatives:" footerStyle="text-align:right" />
                        <Column :footer="totalBaseline" footerStyle="text-align:center" />
                        <Column :footer="totalBoosted" footerStyle="text-align:center" />
                        <Column :footer="totalDelta" footerStyle="text-align:center">
                           <template #body="slotProps">+ {{ slotProps.data.totalDelta }} </template>
                        </Column>
                     </Row>
                     <Row>
                        <Column footer="Averages:" footerStyle="text-align:right" />
                        <Column :footer="averageBaseline" footerStyle="text-align:center" />
                        <Column :footer="averageBoosted" footerStyle="text-align:center" />
                        <Column :footer="averageDelta" footerStyle="text-align:center">
                           <template #body="slotProps">+ {{ slotProps.data.averageDelta }} </template>
                        </Column>
                     </Row>
                  </ColumnGroup>
               </DataTable>
            </div>
         </Panel>
         <Panel ref="summaryPanel" header="Scoring Chart" toggleable :collapsed="true"
            v-if="ora.completionStatus == 'Complete'">
            <div class="chart" v-if="ora.scoring?.chart.image">
               <div class="chartTitle">Results Chart</div>
               <img :src="ora.scoring.chart?.image" />
            </div>
         </Panel>

         <Panel ref="summaryPanel" header="ORA Input Details" toggleable :collapsed="true"
            v-if="ora.completionStatus == 'Complete'">
            <div class="m-0" v-html="ora.responses.summary" />
         </Panel>

         <div class="toolbar">
            <Button  severity="info" raised  @click="openOra" icon="pi"  aria-label="Go to ORA start page" v-if="ora.completionStatus == 'InProgress'">Open this ORA </Button>
            <Button   severity="warning" raised  @click="setInProgress" icon="pi"  aria-label="Set to in-progress" v-if="ora.completionStatus == 'Complete'">Set to In-Progress</Button>
            <Button severity="danger" raised  @click="deleteOra" icon="pi" aria-label="Delete">Delete</Button>
         </div>

      </div>

   </div>
</template>

<script>
export default {
   props: {
      ora: {
         type: Object,
         required: true,
      },
   },
   name: 'OraDetail',

   data() {
      return {

         scoringData: null,

         totalBaseline: 0,
         totalBoosted: 0,
         totalDelta: 0,

         averageBaseline: 0,
         averageBoosted: 0,
         averageDelta: 0,

      };
   },
   computed: {
      oraLoaded() {
         return this.ora != null;
      },


   },
   created() {
      console.log("entering created");


      console.log("exiting created");
   },
   mounted() {
      console.log("entering mounted");

      this.calcScoringData();

      console.log("exiting mounted");
   },

   methods: {

      calcScoringData() {
         console.log("entering calcScoringData()");

         if (!this.ora.scoring?.data) { return; }

         let data = this.ora.scoring.data;
         let baselines = data.datasets.find(d => d.label == 'Baseline Readiness');
         console.log("baselines", baselines);
         let boosted = data.datasets.find(d => d.label == 'With ArenaCX Boosts');
         console.log("boosted", boosted);
         let result = [];

         this.totalBaseline = baselines.data.reduce((a, b) => a + b, 0);
         this.totalBoosted = boosted.data.reduce((a, b) => a + b, 0);
         this.totalDelta = this.totalBoosted - this.totalBaseline

         this.averageBaseline = Math.round(this.totalBaseline / baselines.data.length);
         this.averageBoosted = Math.round(this.totalBoosted / boosted.data.length);
         this.averageDelta = Math.round(this.totalDelta / boosted.data.length);

         data.labels.forEach((s, i) => {
            let row = {
               section: s,
               baseline: baselines.data[i],
               boosted: boosted.data[i],
               delta: boosted.data[i] - baselines.data[i],
            };

            row.deltaCss = 'low';
            row.deltaCss = row.delta >= 40 ? 'medium' : row.deltaCss;
            row.deltaCss = row.delta >= 80 ? 'high' : row.deltaCss;

            result.push(row);
         });

         this.scoringData = result;
         console.log("this.scoringData", this.scoringData);
      },

      segment(code) {
         //console.log("code", code);
         let codes = { smb: "Small Business", middle_market: "Mid-Market", enterprise: "Enterprise" };
         return codes[code] ?? '[Segment unknown]';
      },

      deleteOra() {
         this.$emit('delete-ora', this.ora.recordId);
      },

      setInProgress() {
         this.$emit('set-in-progress', this.ora.recordId);
      },

      openOra() {
         window.open(`https://discover.arenacx.com/#/member/ora/survey/${this.ora.recordId}`, "_new");
      }, 

      formatDate(datestamp) {
         let date = new Date(datestamp);
         return date.toLocaleDateString();

      },
   },
};

</script>

<style scoped>
.p-button {
   min-width: 10rem !important;
}

.detailsContainer {
   margin: 2rem;
   padding: 2rem;
}

.companyName {
   font-size: 1.5em;
   font-weight: bold;
   margin-bottom: 1rem;
}

.completionStatus {
   font-size: 1.5em;
   font-weight: bold;
   margin-bottom: 1rem;
   text-align: right;

   &.complete {
      color: darkgreen;
   }

   &.inProgress {
      color: darkred;
   }
}

.infoHeader {

   margin-bottom: 0.2rem;
   border-bottom: 1px solid #dddddd;
   padding-bottom: 0.2rem;

   &.name {
      font-weight: 500;
   }

   &.value {
      padding-bottom: 0.2rem;
   }
}

.chartTitle {
   font-size: 1.2em;
   font-weight: bold;
   margin-bottom: 1rem;
}

.chart {
   padding: 2rem;
   border: 1px solid #dddddd;
   border-radius: 3px;
   box-shadow: 3px 3px 3px #dddddd;
}

.deltaScore {
   font-weight: bold;
   display: inline-block;
   width: 2rem;

   &.low {
      color: darkgreen;
      background-color: lightgreen;
   }

   &.medium {
      color: rgb(151, 84, 2);
      background-color: rgb(251, 251, 178);
   }

   &.high {
      color: darkred;
      background-color: lightpink;
   }
}

.hubspotLinks {
   color: rgb(169, 85, 24);
   font-weight: 700;
}

.toolbar {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-end;
   background-color: #eae9e9;
   border: 1px solid #dddddd;
   border-radius: 4px;
   padding: 1rem;
   margin-top: 10rem;
   text-align: right;
}
</style>