<template>
   <div class="card flex justify-content-center">
      <Splitter style="height: 100%">
         <SplitterPanel class="flex align-items-center justify-content-center" :size="25" :minSize="10">
            <div class="oraListHeader">Outsourcing Readiness Assessments</div>
            <div class="oraListSubheader">Use this screen to monitor the ORAs that have been collected.  Note that some are In-Progress while others are Complete.</div>

            <div v-if="allORAs == null" class="flex justify-content-center">
               <span style="font-size: 1.3rem;">Loading...</span>
            </div>

            <div v-if="matchingORAs != null">
               <Listbox v-model="selectedOra" filter optionLabel="responses.companyInfo.name" :options="matchingORAs"
                  :listStyle="{ maxHeight: '950px' }" @change="oraSelected" filterPlaceholder="Filter by name...">
                  <template #option="slotProps">
                     <div class="oraCard">
                        <div class="companyName">{{ companyName(slotProps.option.responses.companyInfo) }}</div>
                        <div class="cardDetail segment">Started: {{ formatDate(slotProps.option.metadata.created_ts) }}
                        </div>
                        <div class="cardDetail segment">Segment: {{
                           segment(slotProps.option.responses.companyInfo?.sizeSegmentationCode) }}</div>
                        <div class="cardDetail status">ORA status: {{ slotProps.option.completionStatus }}</div>
                        <div class="cardDetail id">ID: {{ slotProps.option.recordId }}</div>
                     </div>
                  </template>
               </Listbox>
            </div>
         </SplitterPanel>
         <SplitterPanel class=" " :size="75">

            <div v-if="selectedOra == null" class="flex justify-content-center">
               <div style="font-size: 4rem; margin: 12rem 8rem; color: #bae6d0; font-weight: bold;">⬅ Select an ORA from list at left</div>
            </div>

            <div v-if="selectedOra != null" class="flex justify-content-center details">
               <OraDetail :key="magicRefreshKey" :ora="selectedOra" @deleteOra="deleteOra"
                  @setInProgress="setInProgress" />
            </div>



         </SplitterPanel>
      </Splitter>

      <ConfirmDialog />
   </div>
</template>
<script>



import oraService from "./OutsourcingReadinessAssessmentService.js";
import OraDetail from "./OraDetail.vue";

export default {
   components: {
      OraDetail,

   },

   data() {
      return {
         allORAs: null,
         selectedOra: null,

         magicRefreshKey: 0, // doesn't do anything, other than ensurethe <OraDetail> refreshes when the selectedOra changes
      };
   },
   computed: {
      matchingORAs() {
         if (this.allORAs == null) {
            return null;
         }
         return this.allORAs;
      },
   },

   async created() {
      console.log("entering created");

      let data = await oraService.getAll();

      // console.log("data", data[0]);

      this.allORAs = data.sort((a, b) => (a.sortKey > b.sortKey) ? -1 : 1);

      // console.log("this.allORAs", this.allORAs[0]);

      // console.log("this.allORAs", this.allORAs.length);

      console.log("exiting created");
   },

   async mounted() {
      console.log("entering mounted");




      console.log("exiting mounted");
   },

   methods: {
      segment(code) {
         //console.log("code", code);
         let codes = { smb: "Small Business", middle_market: "Mid-Market", enterprise: "Enterprise" };
         return codes[code] ?? '[Segment unknown]';
      },

      companyName(companyInfo) {
         //console.log("companyInfo", companyInfo);
         if (!companyInfo || !companyInfo.name) {
            return "[Unknown Company]";
         }
         return companyInfo.name;
      },

      formatDate(datestamp) {
         let date = new Date(datestamp);
         return date.toLocaleDateString();

      },


      oraSelected(event) {
         //console.log("ora", ora);
         this.selectedOra = event.value;
         this.magicRefreshKey++;
         //console.log("oraSelected", this.selectedOra);
      },

      async deleteOra(recordId) {
         console.log("deleteOra", recordId);

         this.$confirm.require({
            message: 'Do you want to delete this Assessment?  This cannot be reversed.',
            header: 'Delete ORA',
            icon: 'pi pi-info-circle',
            acceptClass: 'p-button-danger',

            // if user does agree with the confirmation, we delete (archive) the ORA
            accept: async () => {
               this.$toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Record deleted', life: 3000 });
               await oraService.deleteById(recordId);
               this.allORAs = this.allORAs.filter(ora => ora.recordId != recordId);
               this.selectedOra = null;
            },
            reject: () => {
               //this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
               return;
            }
         });
      },

      async setInProgress(recordId) {
         console.log("setInProgress", recordId);

         this.$confirm.require({
            message: 'Do you want to set this ORA to be "In Progress".',
            header: 'Set to In-Progress',
            icon: 'pi pi-info-circle',
            acceptClass: 'p-button-warning',

            // if user does agree with the confirmation, we delete (archive) the ORA
            accept: async () => {
               this.$toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Set to In-Progress', life: 3000 });
               let ora = this.allORAs.find(ora => ora.recordId == recordId);
               console.log("ora", ora);
               ora.completionStatus = "InProgress";
               console.log("ora", ora);
               await oraService.update(ora);              
            },
            reject: () => {
               //this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
               return;
            }
         });

      }



   },

};
</script>
<style scoped>
.oraListHeader {
   font-size: 1.5rem;
   font-weight: bold;
   padding: 1rem;
}
.oraListSubheader {
   font-size: 0.9rem;
   padding: 0rem 1rem 1rem 1rem;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
   margin: 0rem;
}

.oraCard {
   border: 1px solid black;
   margin: 0rem;
   padding: 1rem;
   width: 100%;
   height: 8rem;
   min-width: 100px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   flex-wrap: nowrap;
   white-space: nowrap;
}

.companyName {
   font-weight: bold;
   font-size: 1.2rem;
   overflow-x: hidden;
   white-space: nowrap;
}

.cardDetail {
   font-size: 0.8rem;
   padding-left: .7rem;
   white-space: nowrap;
}
</style>
 