<template>
    <div class="toolbar-wrap">
        <div>
            <p class="field-title" style="margin-left: 0.5rem">
                Company Contacts
                <Tooltip description="Manage your company's contact persons." />
            </p>
        </div>
        <Button :disabled="isChannel" label="Add Contact" class="mr-2 inline-block add-new-btn" @click="openNew" />

    </div>
    <div class="table-wrap">
        <table class="contacts-table" v-if="contacts?.length">
            <thead>
                <tr>
                    <th>Actions</th>
                    <th>Name</th>
                    <th>Title</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Primary Contact</th>
                    <th>Escalation Contact</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(contact, index) in contacts" :key="index">
                    <td class="actions-column">
                        <Button disabled="isChannel" icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2"
                            @click="editContact(contact, index)" />
                        <Button disabled="isChannel" icon="pi pi-trash" class="p-button-rounded p-button-warning"
                            @click="deleteContact(contact, index)" />
                    </td>
                    <td>{{ contact.name }}</td>
                    <td>{{ contact.title }}</td>
                    <td>{{ contact.email }}</td>
                    <td>{{ contact.phone }}</td>
                    <td class="center-column">
                        <i :class="['pi', contact.isPrimary ? 'pi-check' : 'pi-times']"></i>
                    </td>
                    <td class="center-column">
                        <i :class="['pi', contact.isEscalation ? 'pi-check' : 'pi-times']"></i>
                    </td>
                </tr>
            </tbody>
        </table>

        <Dialog v-model:visible="contactDialog" :style="{ width: '600px' }" header="Contact Details" :modal="true"
            class="p-fluid">
            <div class="field">
                <label for="name">Name</label>
                <InputText id="name" v-model="contact.name" required="true" />
            </div>

            <div class="field">
                <label for="title">Title</label>
                <InputText id="title" v-model="contact.title" required="true" />
            </div>

            <div class="field">
                <label for="email">Email</label>
                <InputText id="email" v-model="contact.email" required="true" type="email" />
            </div>

            <div class="field">
                <label for="phone">Phone</label>
                <InputText id="phone" v-model="contact.phone" required="true" />
            </div>

            <div class="field-checkbox mb-1">
                <Checkbox id="primary" v-model="contact.isPrimary" :binary="true" 
                    @change="handlePrimaryChange" />
                <label for="primary" style="margin-left: 1rem">Set as Primary Contact</label>
            </div>


            <div class="field-checkbox">
                <Checkbox id="escalation" v-model="contact.isEscalation" :binary="true" />
                <label for="escalation" style="margin-left: 1rem">Set as Escalation Contact</label>
            </div>

            <template #footer>
                <Button label="Cancel" class="p-button p-component p-button-outlined button" @click="hideDialog" />
                <Button v-if="editIndex === null" label="Add" class="p-button p-component button"
                    @click="onContactSave" />
                <Button v-else label="Update" class="p-button p-component button" @click="onContactSave" />
            </template>
        </Dialog>

        <Dialog v-model:visible="deleteContactDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 1.75rem; margin-right: 0.5rem" />
                <span v-if="contact">Are you sure you want to delete <b>{{ contact.name }}</b>?</span>
            </div>
            <template #footer>
                <Button label="No" class="p-button p-component p-button-outlined button"
                    @click="deleteContactDialog = false" />
                <Button label="Yes" class="p-button p-component button" @click="onConfirmDeleteContact" />
            </template>
        </Dialog>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import Tooltip from '../Tooltip.vue';

export default {
    name: 'ProfileContacts',
    props: ['saveInd', 'contactValues', 'isChannel'],
    components: {
        Tooltip,
    },
    data() {
        return {
            contacts: [],
            contactDialog: false,
            deleteContactDialog: false,
            contact: {},
            submitted: false,
            editIndex: null,
            deleteContactIndex: null,
            intializeOnce: true,
            paginationData: { columnOffset: 0 }
        };
    },
    setup() {
        const v = useVuelidate({ $stopPropagation: true });
        return { v };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        contactValues: {
            immediate: true,
            handler(newVal) {
                try {
                    console.log('Received contactValues:', newVal);
                    this.contacts = newVal || [];
                    console.log('Updated contacts:', this.contacts);
                } catch (err) {
                    console.error('Error in contactValues watch:', err);
                }
            }
        },
    },
    methods: {
        handlePrimaryChange(event) {
            if (event === true) {
                const existingPrimary = this.contacts.some(c => 
                    c.isPrimary && 
                    (this.editIndex === null || this.contacts[this.editIndex] !== c)
                );
                
                if (existingPrimary) {
                    this.contact.isPrimary = false;
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Only one primary contact is allowed',
                        life: 3000,
                    });
                }
            }
        },
        checkAndUpdateEscalationContact() {
            if (this.contact.isEscalation) {
                this.contacts.forEach(contact => {
                    if (contact !== this.contact) {
                        contact.isEscalation = false;
                    }
                });
            }
        },
        onPage(event) {
            this.paginationData = {
                page: event?.page,
                pageCount: event?.pageCount,
                rows: event?.rows,
                columnOffset: event?.page * event?.rows,
            };
        },
        openNew() {
            this.contact = {};
            this.submitted = false;
            this.contactDialog = true;
        },
        hideDialog() {
            this.contactDialog = false;
            this.submitted = false;
            this.editIndex = null;
        },
        editContact(contact, index) {
            this.contact = { ...contact };
            this.editIndex = this.paginationData?.columnOffset + index;
            this.contactDialog = true;
        },
        deleteContact(contact, index) {
            this.contact = contact;
            this.deleteContactIndex = this.paginationData?.columnOffset + index;
            this.deleteContactDialog = true;
        },
        onConfirmDeleteContact() {
            this.contacts.splice(this.deleteContactIndex, 1);
            this.deleteContactDialog = false;
            this.contact = {};
            this.validationCall();
        },
        onContactSave() {
            if (!this.contact.name || this.contact.name.trim() === '') {
                return this.$toast.add({
                    severity: 'error',
                    summary: 'Error while creating contact',
                    detail: 'Please enter name',
                    life: 3000,
                });
            }

            // Check for existing primary contact
            const existingPrimary = this.contacts.some(c => 
                c.isPrimary && (!this.editIndex || this.contacts[this.editIndex] !== c)
            );
            
            if (this.contact.isPrimary && existingPrimary) {
                return this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Only one primary contact is allowed',
                    life: 3000,
                });
            }

            // Trim all string values
            if (this.contact.name) this.contact.name = this.contact.name.trim();
            if (this.contact.title) this.contact.title = this.contact.title.trim();
            if (this.contact.email) this.contact.email = this.contact.email.trim();
            if (this.contact.phone) this.contact.phone = this.contact.phone.trim();

            this.checkAndUpdateEscalationContact();

            if (this.editIndex === null) {
                this.contacts = [...this.contacts, this.contact];
            } else {
                this.contacts.splice(this.editIndex, 1, this.contact);
                this.editIndex = null;
            }

            this.hideDialog();
            this.validationCall();
        },
        validationCall() {
            // Check if there's at least one primary contact
            const hasPrimaryContact = this.contacts.some(contact => contact.isPrimary);
            const errors = this.v.$silentErrors.length;
            
            if (!hasPrimaryContact) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'At least one primary contact is required',
                    life: 3000,
                });
                // Pass true as first argument to indicate validation error
                this.$emit('submit', true, this.contacts);
            } else {
                // Pass false as first argument to indicate no validation error
                this.$emit('submit', errors, this.contacts);
            }
            this.v.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
// ... [Keep existing styles from ProfileAddress.vue] ...
::v-deep(.p-multiselect) {
    margin: 0.75rem 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 2rem;
}

::v-deep(.p-inputtext) {
    margin: 0.75rem 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.add-new-btn {
    margin-top: 1.6rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}

::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}

::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}

::v-deep(.p-datatable) {
    .p-datatable-thead>tr>th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}

.field {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0.75rem;
}

.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.table-wrap {
    margin-top: 6rem;
    margin-bottom: 4rem;
}

.toolbar-wrap {
    width: 100%;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.5rem;
    margin-top: 2rem;
}

.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}

.contacts-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
    background: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.contacts-table th,
.contacts-table td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid #eee;
}

.contacts-table th {
    background: #fbfbfb;
    color: #707683;
    font-weight: 500;
}

.center-column {
    text-align: center;
}

.center-column i {
    font-size: 1.1rem;
}

.pi-check {
    color: #00796b;
}

.pi-times {
    color: #707683;
}


.mb-1 {
    margin-bottom: 1rem;
}

</style>